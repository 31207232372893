<template>
  <ClientOnly>
    <Teleport to="body">
      <!-- 1. Generic Add to trolley / Buy now modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="add-to-trolley-generic"
          v-model:visible="trolleyStore.preview_overlay_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalAddToTrolley
            v-if="trolleyStore.previewed_product"
            :collection-availability="trolleyStore.collection_availability"
            :selected-variant-label="
              trolleyStore.previewed_product.pack_size ||
              trolleyStore.previewed_product.name
            "
            :loading="trolleyStore.is_updating_item_details_in_add_to_trolley"
            :add-to-trolley-cta-loading="
              trolleyStore.add_to_trolley_cta_loading
            "
            :buy-now-cta-loading="trolleyStore.buy_now_cta_loading"
            :data-testid="`add-to-trolley-modal-for-product-${trolleyStore.previewed_product.code}`"
            @set-branch="handleSetBranchFromAddToTrolleyModal"
            @find-stock="findNearByStock(trolleyStore.previewed_product_selected_quantity)"
            @add-to-trolley="(q: number) => handleAddToTrolleyAction(q)"
            @buy-now="(q: number) => handleBuyNowAction(q)"
            @close="trolleyStore.preview_overlay_visible = false"
            @click-variants="handleVariantClickFromAddToTrolleyModal"
            @notify="(code, name) => handleNotifyMe(code, name)"
          />
        </TsDrawer>
      </template>
      <template v-else-if="isDesktop">
        <TsModal
          data-testid="add-to-trolley-generic"
          v-model:visible="trolleyStore.preview_overlay_visible"
          append-class="max-h-[80vh] bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalAddToTrolley
              v-if="trolleyStore.previewed_product"
              :collection-availability="trolleyStore.collection_availability"
              :selected-variant-label="
                trolleyStore.previewed_product.pack_size ||
                trolleyStore.previewed_product.name
              "
              :loading="trolleyStore.is_updating_item_details_in_add_to_trolley"
              :add-to-trolley-cta-loading="
                trolleyStore.add_to_trolley_cta_loading
              "
              :buy-now-cta-loading="trolleyStore.buy_now_cta_loading"
              :data-testid="`add-to-trolley-modal-for-product-${trolleyStore.previewed_product.code}`"
              @set-branch="handleSetBranchFromAddToTrolleyModal"
              @find-stock="findNearByStock(trolleyStore.previewed_product_selected_quantity)"
              @add-to-trolley="(q: number) => handleAddToTrolleyAction(q)"
              @buy-now="(q: number) => handleBuyNowAction(q)"
              @close="trolleyStore.preview_overlay_visible = false"
              @click-variants="handleVariantClickFromAddToTrolleyModal"
              @notify="(code, name) => handleNotifyMe(code, name)"
            />
          </template>
        </TsModal>
      </template>

      <!-- 2. Product variations modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="product-variant-modal"
          v-model:visible="trolleyStore.product_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalProductVariants
            v-if="trolleyStore.previewed_product"
            :main-product="trolleyStore.previewed_product"
            :variants="trolleyStore.previewed_product_variations"
            @close="trolleyStore.product_variants_modal_visible = false"
            @select="
              (selectedVariant) => handleVariantSelection(selectedVariant)
            "
            @notify="
              (outOfStockVariant) =>
                handleNotifyMe(
                  outOfStockVariant.code,
                  outOfStockVariant.full_name || outOfStockVariant.name
                )
            "
          />
        </TsDrawer>
      </template>

      <template v-else-if="isDesktop">
        <TsModal
          data-testid="product-variant-modal"
          v-model:visible="trolleyStore.product_variants_modal_visible"
          append-class="bg-transparent overflow-hidden max-w-[450px]"
        >
          <template #modal-content>
            <TsModalProductVariants
              v-if="trolleyStore.previewed_product"
              :main-product="trolleyStore.previewed_product"
              :variants="trolleyStore.previewed_product_variations"
              @close="trolleyStore.product_variants_modal_visible = false"
              @select="
                (selectedVariant) => handleVariantSelection(selectedVariant)
              "
              @notify="
                (outOfStockVariant) =>
                  handleNotifyMe(
                    outOfStockVariant.code,
                    outOfStockVariant.full_name || outOfStockVariant.name
                  )
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- 3. Confirmation modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="trolley-multiple-items-confirmation-drawer-mobile"
          v-model:visible="
            trolleyStore.multiple_items_confirmation_modal_visible
          "
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <TsModalTrolleyMultipleItemsConfirmation
            :products="trolleyStore.variant_items_added_to_trolley"
            :branch="branchStore.lastSavedBranch?.name"
            @close="
              trolleyStore.multiple_items_confirmation_modal_visible = false
            "
            @go-to-trolley="handleTrolleyPageNavigation"
            @add-recommendation-to-trolley="
              (item) => handleRecommendationAddToTrolley(item)
            "
          />
        </TsDrawer>
      </template>

      <template v-else-if="isDesktop">
        <TsModal
          data-testid="trolley-multiple-items-confirmation-modal-desktop"
          v-model:visible="
            trolleyStore.multiple_items_confirmation_modal_visible
          "
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <template #modal-content>
            <TsModalTrolleyMultipleItemsConfirmation
              :products="trolleyStore.variant_items_added_to_trolley"
              :branch="branchStore.lastSavedBranch?.name"
              @close="
                trolleyStore.multiple_items_confirmation_modal_visible = false
              "
              @go-to-trolley="handleTrolleyPageNavigation"
              @add-recommendation-to-trolley="
                (item) => handleRecommendationAddToTrolley(item)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- 4. OOS Notification enable modal -->
      <template v-if="isMobile">
        <TsDrawer
          v-model:visible="productStore.subscribe_notification_modal_visible"
          data-testid="subscribe-notification-modal"
          position="bottom"
          append-class="bg-transparent overflow-hidden min-h-48"
        >
          <TsModalOutOfStockSubscription
            @close="productStore.subscribe_notification_modal_visible = false"
            @subscribe="
              productStore.subscribe_notification_modal_visible = false
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          v-model:visible="productStore.subscribe_notification_modal_visible"
          data-testid="subscribe-notification-modal"
          append-class="max-w-[450px]"
        >
          <template #modal-content>
            <TsModalOutOfStockSubscription
              @close="productStore.subscribe_notification_modal_visible = false"
              @subscribe="
                productStore.subscribe_notification_modal_visible = false
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- 5. OOS subscription confirmation modal -->
      <template v-if="isMobile">
        <TsDrawer
          v-model:visible="productStore.subscription_successful_modal_visible"
          data-testid="subscription-successful"
          position="bottom"
          append-class="bg-transparent overflow-hidden min-h-48"
        >
          <TsModalSubscriptionConfirmation
            :productName="productStore.notify_product_name"
            :email="productStore.notify_user_email"
            @close="productStore.subscription_successful_modal_visible = false"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          v-model:visible="productStore.subscription_successful_modal_visible"
          data-testid="subscription-successful"
          append-class="max-w-[450px]"
        >
          <template #modal-content>
            <TsModalSubscriptionConfirmation
              :productName="productStore.notify_product_name"
              :email="productStore.notify_user_email"
              @close="
                productStore.subscription_successful_modal_visible = false
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Nearby stock search modal and actions -->
      <TsFindStockNearbyActions v-if="trolleyStore.previewed_product" :product="trolleyStore.previewed_product" data-testid="PLP-nearby-stock-finder" />

    </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { DeliveryMethodCodes } from "~/assets/constants/ecomApi";
import type { Product } from "~/types/ecom/product/product.type";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

const { isMobile, isDesktop } = useDevice();

const localePath = useLocalePath();

const authStore = useAuthStore();
const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();
const productStore = useProductStore();

const isBranchSet = branchStore.is_branch_set;

/* Helpers */
function calculateTrolleySummaryOnTrolleyRoute() {
  const route = useRoute();
  if (route.path.includes("trolley")) trolleyStore.setOrderTotals();
}

/* Recommendation actions */
async function handleRecommendationAddToTrolley(addOnItem: Product) {
  trolleyStore.variant_items_added_to_trolley = [
    {
      ...addOnItem,
      quantity: 1,
      channel: TrolleyChannel.Delivery,
    },
  ];
  useRootStore().toastSuccess(["Item added to cart for delivery"], "top-right");
  await trolleyStore.dispatchItemToTrolley(
    addOnItem.code,
    1,
    TrolleyChannel.Delivery,
    DeliveryMethodCodes.Delivery
  );
  calculateTrolleySummaryOnTrolleyRoute();
}

/* Trolley actions */
async function handleBuyNowAction(quantity: number) {
  if (!trolleyStore.previewed_product) return;

  trolleyStore.buy_now_cta_loading = true;

  const checkoutStore = useCheckoutStore();

  // Direct ship delivery
  if (trolleyStore.previewed_product.direct_ship)
    await checkoutStore.addToBuyNowForDirectship(
      trolleyStore.previewed_product.code,
      quantity
    );
  // Next day collection
  else if (
    trolleyStore.previewed_product_selected_channel ===
      TrolleyChannel.Collection &&
    trolleyStore.collection_availability?.status === "NextDayCollection"
  )
    await checkoutStore.addToBuyNowForNextDayCollection(
      trolleyStore.previewed_product.code,
      quantity
    );
  // Collection
  else if (
    trolleyStore.previewed_product_selected_channel ===
      TrolleyChannel.Collection &&
    trolleyStore.collection_availability?.status === "Collection"
  )
    await checkoutStore.addToBuyNowForCollection(
      trolleyStore.previewed_product.code,
      quantity
    );
  // Delivery
  else
    await checkoutStore.addToBuyNowForDelivery(
      trolleyStore.previewed_product.code,
      quantity
    );

  trolleyStore.buy_now_cta_loading = false;
  hideAllListingPageModals();
  await navigateTo(localePath("/buy-now"));
}

async function handleAddToTrolleyAction(quantity: number) {
  if (!trolleyStore.previewed_product) return;

  useMonetateStore().getProductAddons();

  trolleyStore.variant_items_added_to_trolley = [];
  trolleyStore.add_to_trolley_cta_loading = true;

  // Direct ship delivery
  if (trolleyStore.previewed_product.direct_ship) {
    await trolleyStore.addToTrolleyForDirectship(
      trolleyStore.previewed_product.code,
      quantity
    );
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.Directship,
      },
    ];
  }

  // Next day collection
  else if (
    trolleyStore.previewed_product_selected_channel ===
      TrolleyChannel.Collection &&
    trolleyStore.collection_availability?.status === "NextDayCollection"
  ) {
    await trolleyStore.addToTrolleyForNextDayCollection(
      trolleyStore.previewed_product.code,
      quantity
    );
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.NextDayCollection,
      },
    ];
  }

  // Collection
  else if (
    trolleyStore.previewed_product_selected_channel ===
    TrolleyChannel.Collection
  ) {
    if (checkItemForNextDayCollection()) {
      await trolleyStore.addToTrolleyForNextDayCollection(
        trolleyStore.previewed_product.code,
        quantity
      );
      trolleyStore.variant_items_added_to_trolley = [
        {
          ...trolleyStore.previewed_product,
          quantity,
          channel: TrolleyChannel.NextDayCollection,
        },
      ];
    } else {
      await trolleyStore.addToTrolleyForCollection(
        trolleyStore.previewed_product.code,
        quantity
      );
      trolleyStore.variant_items_added_to_trolley = [
        {
          ...trolleyStore.previewed_product,
          quantity,
          channel: TrolleyChannel.Collection,
        },
      ];
    }
  }

  // Delivery
  else {
    await trolleyStore.addToTrolleyForDelivery(
      trolleyStore.previewed_product.code,
      quantity
    );
    trolleyStore.variant_items_added_to_trolley = [
      {
        ...trolleyStore.previewed_product,
        quantity,
        channel: TrolleyChannel.Delivery,
      },
    ];
  }

  trolleyStore.add_to_trolley_cta_loading = false;

  setTimeout(() => {
    trolleyStore.preview_overlay_visible = false;
    trolleyStore.multiple_items_confirmation_modal_visible = true;
  }, 500);

  calculateTrolleySummaryOnTrolleyRoute();
}

function checkItemForNextDayCollection() {
  if (!trolleyStore.previewed_product) return false;
  // check if item already exists in trolley
  const trolleyCollectionLineItem = trolleyStore.findTrolleyItemByProductCode(
    trolleyStore.previewed_product.code
  );
  if (!trolleyCollectionLineItem) return false;
  const collectionStock =
    trolleyStore.previewed_product.stockDetails?.collection ?? 0;
  const deliveryStock =
    trolleyStore.previewed_product.stockDetails?.delivery ?? 0;
  const quantityToAdd = trolleyStore.previewed_product_selected_quantity;
  const totalQuantity = trolleyCollectionLineItem.quantity + quantityToAdd;
  // if total quantity exceeds collection stock and is in trolley
  if (totalQuantity > collectionStock && deliveryStock > 0) return true;
  return false;
}

function updateSelectedChannel(product: Product) {
  const { outOfStockForCollection, outOfStockForDelivery } = product;

  if (!outOfStockForCollection && isBranchSet && outOfStockForDelivery)
    trolleyStore.previewed_product_selected_channel = TrolleyChannel.Collection;
  else if (!outOfStockForDelivery)
    trolleyStore.previewed_product_selected_channel = TrolleyChannel.Delivery;
  else trolleyStore.previewed_product_selected_channel = null as any;
}

async function handleVariantSelection(selectedVariant: Product) {
  trolleyStore.previewed_product = selectedVariant;
  trolleyStore.product_variants_modal_visible = false;
  // Update preferred channel as per stock for faster checkout
  updateSelectedChannel(selectedVariant);
  trolleyStore.collection_availability =
    await trolleyStore.evaluateCollectionAvailability(selectedVariant);
  // Based on source
  if (!trolleyStore.active_trolley_cta_v1.length)
    trolleyStore.active_trolley_cta_v1 = "BothCTA";
  trolleyStore.is_added = false;
  trolleyStore.preview_overlay_visible = true;
}

async function handleSetBranchFromAddToTrolleyModal() {
  trolleyStore.preview_overlay_visible = false;
  branchStore.branchSelectorModalVisible = true;

  const unwatch = watch(
    () => [branchStore.lastSavedBranch],
    async () => {
      unwatch();
      trolleyStore.preview_overlay_visible = true;
      if (!trolleyStore.previewed_product) return;
      trolleyStore.collection_stock_loading = true;

      trolleyStore.collection_availability =
        await trolleyStore.evaluateCollectionAvailability(
          trolleyStore.previewed_product,
          trolleyStore.previewed_product_selected_quantity,
          true
        );

      trolleyStore.collection_stock_loading = false;
    }
  );
}

async function handleVariantClickFromAddToTrolleyModal() {
  trolleyStore.preview_overlay_visible = false;
  trolleyStore.previewed_product_variations = [];
  trolleyStore.product_variants_modal_visible = true;
  await trolleyStore.getProductVariants(
    trolleyStore.previewed_product?.variations as string[]
  );
}

/* OOS notification actions */
async function handleNotifyMe(
  productCode: string | undefined,
  productName: string | undefined
) {
  const authStore = useAuthStore();
  if (!authStore.is_authenticated) {
    await navigateTo(localePath("/auth/signin"));
    return;
  }
  hideAllListingPageModals();
  productStore.setProductCodeforNotification(productCode, productName);
  productStore.subscribe_notification_modal_visible = true;
}

//-------------------- Find stock near by feature
async function findNearByStock(requiredQuantity: number) {
  if (!trolleyStore.previewed_product) return;

  const currentBranch = branchStore.lastSavedBranch;
  if (!currentBranch) return;
  
  trolleyStore.preview_overlay_visible = false;
  const stockStore = useStockStore();

  // reset old statuses
  stockStore.nearby_stock_search_branch_choice = null;
  branchStore.localitySearchInput = currentBranch.name;
  branchStore.autoCompleteSuggestions = [];
  branchStore.resetStatuses();
  
  stockStore.stock_finder_modal_visible = true;

  stockStore.is_nearby_stock_search_loading = true;

  await handleNearByStockSearch({
    latitude: currentBranch.geolocation.latitude,
    longitude: currentBranch.geolocation.longitude,
    productCode: trolleyStore.previewed_product.code,
    requiredQuantity
  });

  stockStore.is_nearby_stock_search_loading = false;
}

function hideAllListingPageModals() {
  trolleyStore.multiple_items_confirmation_modal_visible = false;
  trolleyStore.preview_overlay_visible = false;
  trolleyStore.product_variants_modal_visible = false;
}

async function handleTrolleyPageNavigation() {
  hideAllListingPageModals();
  const route = useRoute();
  if (route.path.includes("trolley")) {
    const windowRef = useWindowScroll({
      behavior: "smooth",
    });
    windowRef.y.value = 0;
    return;
  }
  await navigateTo(localePath("/trolley"));
}
</script>
